







































































import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'message',
  props: {
    color: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, ctx) {
    const alert = ref(props.value)
    const dismissMessage = () => {
      alert.value = false
      ctx.root.$emit('popup:confirm:close')
    }

    return {
      dismissMessage,
      alert,
    }
  },
})
