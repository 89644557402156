
































































import { defineComponent } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'
import { authorizationTypes, GenericObject } from '@/inc/types'

export default defineComponent({
  name: 'panel-contact',
  props: {
    content: {
      type: Object as () => GenericObject,
      required: true,
    },
  },
  setup() {
    const { i18n } = useState('commune', ['i18n'])
    const { authorizations } = useGetters('user', ['authorizations'])

    return {
      i18n,
      authorizations,
      authorizationTypes,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
