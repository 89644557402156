
































































































































import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import Message from '@/components/ui/Message.vue'
import Consommation from '@/components/commune/components/ConsommationEP.vue'

import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import MessageRecord from '@/components/commune/components/filter/Message.vue'
import PointContact from '@/components/commune/components/PointContact.vue'

export default defineComponent({
  name: 'consommation-eclairage-public',
  components: {
    Message,
    Consommation,
    Breadcrumb,
    Header,
    MessageRecord,
    PointContact,
  },
  setup(_props, ctx) {
    const message = ref(true)
    const { i18n } = useState('commune', ['i18n'])
    const breadcrumb = [
      {
        label: i18n.value.communeConsommationEP.title?.replace(/<[^>]*>/g, ''),
        url: '',
      },
    ]
    const isLoading = ref(true)
    const errorRecord = ref(false)
    const { getRoles } = useGetters('user', ['getRoles'])
    const { FETCH_CONSO_EP } = useActions('commune', ['FETCH_CONSO_EP'])

    onBeforeMount(() => {
      isLoading.value = true
      FETCH_CONSO_EP().then(() => (isLoading.value = false))
    })

    onMounted(() => {
      if (ctx.root.$route.params.data === 'errorRecord') {
        errorRecord.value = true
      }
    })

    const permissions = () => {
      if (
        getRoles.value.includes('CONSO_EP') ||
        getRoles.value.includes('ADMIN')
      ) {
        return true
      }

      return false
    }

    ctx.root.$on('popup:confirm:close', () => {
      errorRecord.value = false
    })

    return {
      message,
      ...useState('user', ['firstname', 'lastname', 'commune']),
      i18n,
      breadcrumb,
      isLoading,
      errorRecord,
      permissions,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
