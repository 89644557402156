




















































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  Ref,
} from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import * as check from '@/composables/commune'
import VueApexCharts from 'vue-apexcharts'
import CustomLink from '@/components/g/CustomLink.vue'
import CheckboxSelector from '@/components/commune/components/filter/CheckboxSelector.vue'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'consommation-ep',
  components: { VueApexCharts, CustomLink, CheckboxSelector },
  setup(props, ctx) {
    const year: Ref<string | undefined> = ref()
    const exportDatas: Ref<check.CommuneEpHistorique[] | undefined> = ref()
    const consoEp = ref(check.getGraphYears())
    // https://apexcharts.com/docs/ Documentations
    const chartOptions = {
      selection: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45,
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
          },
        },
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      legend: {
        show: true,
        onItemClick: {
          toggleDataSeries: false,
        },
        fontFamily: 'proxima-nova, sans-serif;',
        fontSize: '18rem',
        // fontWeight: 400,
        labels: {
          useSeriesColors: false,
        },
        position: 'bottom',
        markers: {
          radius: 12,
          width: 25,
          height: 25,
        },
      },
      chart: {
        width: '100%',
        type: 'bar',
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          vertical: true,
          horizontal: false,
        },
      },
      stroke: {
        colors: ['transparent'],
        width: 5,
        cssClass: 'apexcharts-yaxis-label',
      },
      xaxis: {
        labels: {
          show: true,
          rotate: 0,
          minHeight: 120,
          maxHeight: 120,
          hideOverlappingLabels: false,
          style: {
            fontFamily: 'proxima-nova, sans-serif;',
            fontSize: '1.8rem',
          },
        },
        categories: check.getMonth(),
      },
      yaxis: {
        min: 0,
        tickAmount: 7,
        labels: {
          style: {
            fontFamily: 'proxima-nova, sans-serif;',
            fontSize: '1.8rem',
          },
          formatter(val) {
            if (isNaN(val)) {
              return val
            }

            return `${val.toFixed(2)} MWh`
          },
        },
        // decimalsInFloat: 0,
      },
    }

    const chartOptionsResponsive = JSON.parse(JSON.stringify(chartOptions))
    chartOptionsResponsive.plotOptions.bar.horizontal = true
    chartOptionsResponsive.plotOptions.bar.vertical = false
    chartOptionsResponsive.xaxis.labels.style.fontSize = '1.5rem'

    onMounted(() => {
      exportDatas.value = JSON.parse(JSON.stringify(check.getDataConso()))

      if (exportDatas.value) {
        exportDatas.value.forEach(data => {
          data.date = dayjs(data.date).format('MM/YYYY')
        })
      }

      year.value = check.getDataByYears()[2]?.name.toLowerCase().split(' ')[1]
    })

    ctx.root.$on('checkbox:selector:consommation', data => {
      consoEp.value = data
    })

    const consoTotal = (input = 0) => {
      let total = input
      check.getDataByYears(consoEp.value).forEach(x => (total += x.total))

      return total.toFixed(2)
    }

    const total = computed({
      get() {
        const mask = ref('')
        switch (consoTotal().length) {
          case 4:
            mask.value = '#,##'
            break
          case 5:
            mask.value = '##,##'
            break
          case 6:
            mask.value = '###,##'
            break
          case 7:
            mask.value = '#.###,##'
            break
          case 8:
            mask.value = '##.###,##'
            break
          default:
            break
        }

        return ctx.root.$options?.filters?.VMask(consoTotal(), mask.value)
      },
      set(val: string) {
        consoTotal()
      },
    })

    return {
      check,
      ...useState('commune', ['i18n', 'ep']),
      month: check.getMonth(),
      year,
      exportDatas,
      chartOptions,
      chartOptionsResponsive,
      consoEp,
      consoTotal,
      total,
    }
  },
})
