

































import { defineComponent } from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import { authorizationTypes } from '@/inc/types'
import PanelContact from '@/components/commune/components/PanelContact.vue'
import * as check from '@/composables/commune'

export default defineComponent({
  name: 'point-contact',
  setup() {
    const { i18n } = useState('commune', ['i18n'])
    const { authorizations } = useGetters('user', ['authorizations'])
    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])
    const { hasPanels } = useGetters('sidePanel', ['hasPanels'])

    const CategoriesContact = check.getCategoriesContact()

    const showContact = () => {
      resetPanel()
      showPanel({
        component: {
          template: PanelContact,
        },
        content: {
          contact: CategoriesContact,
        },
      })
    }

    // const active: Ref<boolean> = ref(false)

    const onClick = () => {
      // active.value = true
      showContact()
    }

    return {
      i18n,
      authorizations,
      authorizationTypes,
      showContact,
      // active,
      onClick,
      hasPanels,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
