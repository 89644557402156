


















































































































































































































































































import { useState } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'
import CustomLink from '@/components/g/CustomLink.vue'
import * as check from '@/composables/commune'

export default defineComponent({
  name: 'checkbox-selector',
  components: { CustomLink },
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    edit: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    search: {
      type: Boolean,
      required: false,
      default() {
        return false
      },
    },
  },

  setup(props, ctx) {
    const values = ref(props.edit)
    const isOpen = ref(false)
    const searchTerms = ref('')
    const result = ref(props.options)

    const sendEmit = data => {
      isOpen.value = false
      ctx.root.$emit(`checkbox:selector:${props.type}`, data)
    }
    const closePanel = () => {
      isOpen.value = false
      searchTerms.value = ''
      result.value = props.options
    }
    const togglePanel = () => {
      isOpen.value = !isOpen.value
      searchTerms.value = ''
      result.value = props.options
    }
    const filterResults = () => {
      result.value = props.options.filter(item =>
        item.toLowerCase().includes(searchTerms.value.toLowerCase())
      )
    }

    return {
      isOpen,
      closePanel,
      togglePanel,
      values,
      sendEmit,
      ...useState('commune', ['i18n']),
      check,
      searchTerms,
      filterResults,
      result,
    }
  },
})
